var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"overlay-color":"rgba(34, 34, 34, 0.27)","overlay-opacity":"0.8","max-width":"520","persistent":""},on:{"click:outside":_vm.close},model:{value:(_vm.state),callback:function ($$v) {_vm.state=$$v},expression:"state"}},[_c('v-card',[(_vm.dialogLoading)?_c('SkeletonPreloader',{attrs:{"count":1,"type":'list-item-two-line',"width":'100%',"height":'13vh'}}):[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.addPayment)}}},[_c('div',{staticClass:"d-flex flex-row flex-grow-1 justify-center pt-5"},[_c('img',{staticStyle:{"width":"100px"},attrs:{"src":require("@/assets/images/wallet.svg")}})]),_c('v-card-title',{staticClass:"black--text d-flex flex-column flex-grow-1 justify-center ft-light text-center text-md"},[_c('span',{staticClass:"ft font-weight-medium text-md"},[_vm._v("Make Payment")]),_c('span',{staticClass:"ft text-sm",staticStyle:{"line-height":"1.4"}},[_vm._v(" Running out of credit? Don't worry, you can top it up with ease ")])]),_c('v-card-text',[_c('div',{staticClass:"d-flex flex-column flex-grow-1"},[_c('span',{staticClass:"ft pb-2 text-center"},[_vm._v("Choose payment option")]),_c('div',{staticClass:"d-flex flex-row justify-center flex-full flex-wrap mb-3"},_vm._l((_vm.paymentOptions),function(option,index){return _c('v-card',{key:index,staticClass:"box-shadow-light pa-3 cursor-pointer mx-1",class:[
                    _vm.payment.channels === option.name
                      ? 'rounded-dotted-mini'
                      : '' ],on:{"click":function($event){$event.stopPropagation();_vm.payment.channels = option.name}}},[_c('div',{staticClass:"d-flex flex-row flex-full flex-grow-1"},[_c('i',{staticClass:"material-icons-outlined mr-2"},[_vm._v(_vm._s(option.icon))]),_c('span',{staticClass:"ft font-weight-medium"},[_vm._v(_vm._s(option.name.split('_').join(' ').toUpperCase()))])])])}),1)]),_c('ValidationProvider',{attrs:{"tag":"div","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('div',{staticClass:"d-flex flex-row flex-full"},[_c('i',{staticClass:"material-icons-outlined mr-4 mt-3",class:errors.length > 0 ? 'error--text' : 'black--text'},[_vm._v("email")]),_c('v-text-field',{staticClass:"ft font-weight-medium text-sm",attrs:{"error-messages":errors[0],"autofocus":"","hint":"Provide your email address here","label":"Enter your email address","persistent-hint":true},model:{value:(_vm.payment.email),callback:function ($$v) {_vm.$set(_vm.payment, "email", $$v)},expression:"payment.email"}})],1)]}}],null,true)}),_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('div',{staticClass:"d-flex flex-row flex-full"},[_c('i',{staticClass:"material-icons-outlined mr-4 mt-3",class:errors.length > 0 ? 'error--text' : 'black--text'},[_vm._v("money")]),_c('v-text-field',{staticClass:"ft font-weight-medium text-sm",attrs:{"error-messages":errors[0],"hint":"Provide your credit amount","label":"Amount","persistent-hint":true},model:{value:(_vm.payment.amount),callback:function ($$v) {_vm.$set(_vm.payment, "amount", $$v)},expression:"payment.amount"}})],1)]}}],null,true)})],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{staticClass:"text-sm ft text-capitalize",attrs:{"text":"","color":"accent"},on:{"click":_vm.close}},[_vm._v("Cancel ")]),_c('v-btn',{staticClass:"ft font-weight-medium text-sm text-capitalize",attrs:{"type":"submit","loading":_vm.loading,"disabled":_vm.loading,"color":"primary"}},[_vm._v("Pay")])],1)],1)]}}])})]],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }